const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "设备",
                prop: "SBMC",
                minWidth: "180"
            }, {
                label: "监测时间",
                prop: "JCSJ",
                minWidth: "180"
            }, {
                label: "温度(℃)",
                prop: "WD",
                minWidth: "100"
            }, {
                label: "湿度(%)",
                prop: "SD",
                minWidth: "100"
            }, {
                label: "风向",
                prop: "FX",
                minWidth: "100"
            }, {
                label: "风速(m/s)",
                prop: "FS",
                minWidth: "100"
            }, {
                label: "二氧化碳(ppm)",
                prop: "CO2",
                minWidth: "120"
            }, {
                label: "二氧化氮(ppb)",
                prop: "NO2",
                minWidth: "120"
            }, {
                label: "PM10",
                prop: "PM10",
                minWidth: "100"
            }, {
                label: "PM2.5",
                prop: "PM25",
                minWidth: "100"
            }, {
                label: "臭氧(ppb)",
                prop: "O3",
                minWidth: "100"
            }, {
                label: "负氧离子",
                prop: "FYLZ",
                minWidth: "100"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        dateData: [{
            placeholder: "请选择开始时间",
            value: 'startTime',
            operateType: 'search',
            isShow: true
        }, {
            placeholder: "请选择结束时间",
            value: 'endTime',
            operateType: 'search',
            isShow: true
        }]
    },
    snButton: {
        buttonData: [{
            //     isShow: true,
            //     btnType: 'button',
            //     operateType: 'buttonNew',
            //     name: '新建',
            //     round: true,
            //     backColor: '#28ccd9',
            // }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };